import { Frame } from '~/lib';
import { ProductEventData } from './models';
import { useAlgoliaEvents } from './useAlgoliaEvents';
import { addToQueue, resetQueue, setIsReady, useEventStore, setList } from './useEventStore';
import { useGTMEvents } from './useGTMEvents';
import { _transformProductsToGAItems } from './useGTMEvents/helper';
import { useLocalEvents } from './useLocalEvents';
import { useRaptorEvents } from './useRaptorEvents';
import { useCustomerStore } from '~/features/commerce-api/hooks/useCustomerStore';
import { priceWithoutVAT } from './helpers';
import { useBasketStore } from '~/features/basket/hooks/useBasket';
import { GTMBasketItemModel } from './useGTMEvents/models';

declare global {
    interface Window {
        dataLayer: any;
        td?: {
            identify?: (id: string) => void;
        };
        CookieInformation?: {
            getConsentGivenFor: (consent: 'cookie_cat_statistic') => boolean;
        };
    }
}
export type EventIdentifier = string;

export function useEvents(frame: Frame | null) {
    const { isPlusMember } = useCustomerStore((state) => ({
        isPlusMember: state?.customer?.isPlusMember as boolean,
    }));

    const { isReady, queue, list } = useEventStore((state) => ({
        isReady: state.isReady,
        queue: state.queue,
        list: state.list,
    }));

    const {
        aiOnInit,
        aiProductAddedToBasket,
        aiProductClicked,
        aiProductPageView,
    } = useAlgoliaEvents();
    const {
        gaAuthenticationEvents,
        gaFilter,
        gaInternalSearch,
        gaProductPage,
        gaStorePickup,
        gaWishlistEvents,
        gtmAddPaymentInfo,
        gtmAddShippingInfo,
        gtmAddCouponCode,
        gtmRemoveCouponCode,
        gtmAddGiftCard,
        gtmRemoveGiftCard,
        gtmAddToCart,
        gtmBeginCheckout,
        gtmInitGPage,
        gtmProductListView,
        gtmPurchase,
        gtmRemoveFromCart,
        gtmSelectItem,
        gtmViewCart,
        gtmViewItem,
    } = useGTMEvents(frame);

    const { userChange } = useRaptorEvents(frame);

    const { setLatestVisitedProduct } = useLocalEvents();

    const isTracking = () => {
        return window.CookieInformation?.getConsentGivenFor('cookie_cat_statistic');
    };

    const runQueue = () => {
        if (!queue.length) return;
        console.debug('Event queue run');
        queue.forEach((x) => x());
        resetQueue();
    };

    const initialize = (customerId?: string, customerNo?: string) => {
        // Prevent track current page when is just leaving it
        const allPageView = window?.dataLayer?.filter(
            (elem: { event: string }) => elem.event === 'pageview'
        );

        const lastPageView = allPageView && allPageView[allPageView.length - 1];
        if (
            isReady ||
            lastPageView?.pagePath !== document.location.pathname + document.location.search
        ) {
            console.debug('Events initialized');
            userChange(customerNo);
        }

        // Initialize
        if (isTracking()) {
            aiOnInit(customerId);
        }

        setIsReady(true);
    };

    function changeUser(customerNo?: string) {
        userChange(customerNo);
    }

    function identifyBasketIdEvent(basketId?: string) {
        console.debug('Identify event added');
        addToQueue(() => {
            console.debug('Identify event run');
            if (!basketId || !window.td || !window.td.identify) {
                return;
            }
            window.td.identify(basketId);
        });
    }

    function productsImpressionsEvent(
        itemListId: string,
        itemListName: string,
        products: ProductEventData[]
    ) {
        if (!products || !products.length) return;
        console.debug('Product impression event added', { products });
        addToQueue(() => {
            console.debug('product impression event run');
            gtmProductListView(
                itemListId,
                itemListName,
                _transformProductsToGAItems(products, { isPlusMember })
            );
        });
    }

    function productClickEvent(
        itemListId: string,
        itemListName: string,
        product: ProductEventData,
        raptorModule?: string
    ) {
        console.debug('Product click event added');
        addToQueue(() => {
            console.debug('Product click event run');
            aiProductClicked(product.id, product.position, product.queryId);
            gtmSelectItem(
                itemListId,
                itemListName,
                _transformProductsToGAItems([product], { isPlusMember }),
                raptorModule
            );
        });
    }

    function productDetailviewEvent(product: ProductEventData, breadcrumb: string) {
        console.debug('Product detail event added');
        addToQueue(() => {
            console.debug('product detail event run');
            setLatestVisitedProduct(product.id);
            aiProductPageView([product.id]);
            gtmViewItem(
                _transformProductsToGAItems([product], {
                    isPlusMember,
                    withIndex: false,
                    withStockStatus: true,
                }),
                breadcrumb
            );
        });
    }

    function addToBasketEvent(product: ProductEventData, quantity = 1) {
        console.debug('Add to basket event added');
        const { basketId: basketID, productItems } = useBasketStore.getState().basket ?? {};

        const basketContent: GTMBasketItemModel[] =
            productItems?.map((product) => ({
                item: product?.productId || '',
                quantity: product?.quantity?.toString() || '',
                price: product?.c_unitPrice || '',
                unique_id: product?.c_ean || '',
            })) || [];

        addToQueue(() => {
            console.debug('Add to basket event run');
            aiProductAddedToBasket([product.id], product.queryId);
            gtmAddToCart({
                items: _transformProductsToGAItems([product], { isPlusMember }),
                quantity,
                basketID,
                basketContent,
            });
        });
    }

    function removeFromBasketEvent(product: ProductEventData, quantity = 1) {
        console.debug('Remove to basket event added');
        const { basketId: basketID, productItems } = useBasketStore.getState().basket ?? {};
        // const basketContent = productItems?.map((product) => product.productId).join(',');
        const basketContent: GTMBasketItemModel[] =
            productItems?.map((product) => ({
                item: product?.productId || '',
                quantity: product?.quantity?.toString() || '',
                price: product?.c_unitPrice || '',
                unique_id: product?.c_ean || '',
            })) || [];

        addToQueue(() => {
            console.debug('Remove to basket event run');
            gtmRemoveFromCart(
                _transformProductsToGAItems([product], { isPlusMember }),
                quantity,
                basketID,
                basketContent
            );
        });
    }

    function submitClickAndReserveEvent(product: ProductEventData) {
        console.debug('Submit click and reserve event added');
        addToQueue(() => {
            console.debug('Submit click and reserve event run');
            aiProductAddedToBasket([product.id], product.queryId);
            gtmAddToCart({
                items: _transformProductsToGAItems([product], { isPlusMember }),
            });
        });
    }

    function viewCartEvent(products: ProductEventData[], value: string) {
        console.debug('View cart event added');
        const valueWithoutVAT = priceWithoutVAT(value)?.toString();
        addToQueue(() => {
            console.debug('View cart event run');
            gtmViewCart(
                _transformProductsToGAItems(products, { isPlusMember, withIndex: false }),
                valueWithoutVAT
            );
        });
    }

    function beginCheckoutEvent(products: ProductEventData[], value: string, coupon: string) {
        console.debug('Begin checkout event added');
        setTimeout(() => {
            addToQueue(() => {
                console.debug('Begin checkout event run');
                const valueWithoutVAT = priceWithoutVAT(value)?.toString();
                gtmBeginCheckout(
                    _transformProductsToGAItems(products, { isPlusMember, withIndex: false }),
                    valueWithoutVAT,
                    coupon
                );
            });
        });
    }

    function addShippingInfoEvent(
        products: ProductEventData[],
        value: string,
        coupon: string,
        shipping: string
    ) {
        console.debug('Add shipping info event added');
        addToQueue(() => {
            console.debug('Add shipping info event run');
            const valueWithoutVAT = priceWithoutVAT(value)?.toString();
            gtmAddShippingInfo(
                _transformProductsToGAItems(products, { isPlusMember, withIndex: false }),
                valueWithoutVAT,
                coupon,
                shipping
            );
        });
    }

    function addPaymentInfoEvent(
        products: ProductEventData[],
        value: string,
        coupon: string,
        payment: string
    ) {
        setTimeout(() => {
            addToQueue(() => {
                const valueWithoutVAT = priceWithoutVAT(value)?.toString();
                gtmAddPaymentInfo(
                    _transformProductsToGAItems(products, { isPlusMember, withIndex: false }),
                    valueWithoutVAT,
                    coupon,
                    payment
                );
            });
        });
    }

    function addCouponCodeEvent(products: ProductEventData[], value: string, coupon: string) {
        console.debug('Add coupon info event added');
        const valueWithoutVAT = priceWithoutVAT(value)?.toString();
        addToQueue(() => {
            console.debug('Add coupon info event run');
            gtmAddCouponCode(
                _transformProductsToGAItems(products, { isPlusMember }),
                valueWithoutVAT,
                coupon
            );
        });
    }

    function removeCouponCodeEvent(products: ProductEventData[], value: string, coupon: string) {
        console.debug('Remove coupon info event added');
        const valueWithoutVAT = priceWithoutVAT(value)?.toString();
        addToQueue(() => {
            console.debug('Remove coupon info event run');
            gtmRemoveCouponCode(
                _transformProductsToGAItems(products, { isPlusMember }),
                valueWithoutVAT,
                coupon
            );
        });
    }

    function addGiftCardEvent(products: ProductEventData[], value: string, coupon: string) {
        console.debug('Add gift card event added');
        const valueWithoutVAT = priceWithoutVAT(value)?.toString();
        addToQueue(() => {
            console.debug('Add gift card event run');
            gtmAddGiftCard(
                _transformProductsToGAItems(products, { isPlusMember }),
                valueWithoutVAT,
                coupon
            );
        });
    }

    function removeGiftCardEvent(products: ProductEventData[], value: string, coupon: string) {
        console.debug('Remove gift card event added');
        const valueWithoutVAT = priceWithoutVAT(value)?.toString();
        addToQueue(() => {
            console.debug('Remove gift card event run');
            gtmRemoveGiftCard(
                _transformProductsToGAItems(products, { isPlusMember }),
                valueWithoutVAT,
                coupon
            );
        });
    }

    function purchaseEvent(
        affiliationOptions: {
            storeId: string | undefined;
            storeName: string | undefined;
            isLoggedIn: boolean | undefined;
            isStorePersonnel: boolean | undefined;
            paymentMethod: string | undefined;
        },
        coupon: string,
        transaction_id: string,
        items: ProductEventData[],
        value: number,
        shipping: number
    ) {
        console.debug('Purchase event added');
        const valueWithoutVAT = priceWithoutVAT(value);
        addToQueue(() => {
            console.debug('Purchase event added run');
            gtmPurchase(
                affiliationOptions,
                coupon,
                transaction_id,
                _transformProductsToGAItems(items, { isPlusMember, withIndex: false }),
                valueWithoutVAT,
                shipping
            );
        });
    }

    /**
     * Custom GA Events
     */
    function filterEvent(eventAction: string, eventLabel: string) {
        console.debug(`Filter ${eventAction} event added`);
        gaFilter(eventAction, eventLabel);
    }

    function PDPActionEvent(eventAction: string, eventLabel: string) {
        console.debug('PDP action event added');
        gaProductPage(eventAction, eventLabel);
    }

    function storePickupEvent(eventAction: string, eventLabel: string) {
        console.debug('Store Pickup event added');
        gaStorePickup(eventAction, eventLabel);
    }

    function internalSearchEvent(eventLabel: string) {
        console.debug('Internal Search event added');
        gaInternalSearch(eventLabel);
    }

    function authenticationEvent(eventAction: string, eventLabel: string | undefined) {
        console.debug('Authentication event added');
        gaAuthenticationEvents(eventAction, eventLabel);
    }

    function wishlistEvent(eventAction: string, eventLabel: string | undefined) {
        console.debug('Wishlist event added');
        gaWishlistEvents(eventAction, eventLabel);
    }

    return {
        isReady,
        initialize,
        pageView: gtmInitGPage,
        productsImpressionsEvent,
        productClickEvent,
        productDetailviewEvent,
        addToBasketEvent,
        removeFromBasketEvent,
        beginCheckoutEvent,
        addShippingInfoEvent,
        addPaymentInfoEvent,
        addCouponCodeEvent,
        removeCouponCodeEvent,
        addGiftCardEvent,
        removeGiftCardEvent,
        viewCartEvent,
        purchaseEvent,
        filterEvent,
        PDPActionEvent,
        storePickupEvent,
        internalSearchEvent,
        authenticationEvent,
        wishlistEvent,
        runQueue,
        hasQueue: !!queue.length,
        list,
        setList,
        setIsReady,
        identifyBasketIdEvent,
        submitClickAndReserveEvent,
        changeUser,
    };
}

import {
    Basket,
    CouponItem,
    PriceAdjustment,
    Product,
    ProductItem,
} from '~/lib/data-contract/salesforce';
import { ProductEventData } from '../models';
import { GTMProductEventModel } from './models';
import { getEffectivePrice } from '../helpers';

type GetAffiliationArgs = {
    isLoggedIn: boolean | undefined;
    isStorePersonnel: boolean | undefined;
    paymentMethod: string | undefined;
    storeId: string | undefined;
    storeName: string | undefined;
};

export function _getGTMAffiliation({
    isLoggedIn,
    isStorePersonnel,
    paymentMethod,
    storeId,
    storeName,
}: GetAffiliationArgs) {
    if (isStorePersonnel) {
        return `InStore - ${storeId} - ${storeName}`;
    }

    return `OnlineStore ${isLoggedIn ? 'logget ind' : 'ikke logget ind'} - ${paymentMethod ?? ''}`;
}

export function _matchCouponsFromCartWithProducts(
    products: Product[] | ProductItem | undefined,
    cartCoupons: CouponItem[] | undefined
) {
    if (!Array.isArray(products)) {
        return { appliedCoupons: {}, appliedCouponCodes: '' };
    }

    const productCouponCodes: { [id: string]: string } = products
        .flatMap(
            ({ priceAdjustments }) =>
                priceAdjustments?.map(({ couponCode }: CouponItem) => couponCode) || []
        )
        .reduce((acc, couponCode) => {
            acc[couponCode] = couponCode;
            return acc;
        }, {});

    const appliedCoupons: {
        [id: string]: { couponId: string | undefined; couponCode: string };
    } = {};
    const appliedCouponCodes: string[] = [];

    if (cartCoupons) {
        for (const { code, couponItemId } of cartCoupons) {
            if (productCouponCodes[code]) {
                for (const product of products) {
                    const productId = product.id ?? product.productId;
                    const couponId = couponItemId ? couponItemId : undefined;
                    appliedCoupons[productId] = { couponId, couponCode: code };
                }
                appliedCouponCodes.push(code);
            }
        }
    }

    return { appliedCoupons, appliedCouponCodes: appliedCouponCodes.join(', ') };
}

export function _getCouponsFromOrder(basket: Basket | undefined) {
    if (!basket) {
        return '';
    }

    const orderCouponCodes: { [id: string]: string } = (basket.orderPriceAdjustments ?? [])
        .filter((adjustment) => adjustment.couponCode !== undefined)
        .reduce((acc: { [id: string]: string }, adjustment: PriceAdjustment) => {
            if (adjustment.couponCode) {
                acc[adjustment.couponCode] = adjustment.couponCode;
            }
            return acc;
        }, {});

    return Object.values(orderCouponCodes).join(', ');
}

type transformProductsToGAItemsOptions = {
    isPlusMember?: boolean | undefined;
    withIndex?: boolean;
    withStockStatus?: boolean;
};

export function _transformProductsToGAItems(
    products: ProductEventData[],
    {
        isPlusMember = false,
        withIndex = true,
        withStockStatus = false,
    }: transformProductsToGAItemsOptions
): GTMProductEventModel[] {
    try {
        return products.map((product) => {
            const prices = product?.cust_prices ?? product.c_prices;
            const price = getEffectivePrice(prices, isPlusMember);

            return {
                item_brand: product?.brand,
                item_category: product?.category,
                item_color: product?.color,
                item_freight_group: product?.freightGroup,
                item_id: product?.ean,
                item_name: product?.name,
                price,
                item_price: price,
                item_product_id: product?.id,
                item_rating: product?.rating,
                item_serie: product?.serie,
                item_variant: product?.variant,
                item_warehouse_type: product?.fullfillment,
                quantity: product?.quantity,
                ...(product.coupon && { coupon: product?.coupon }),
                ...(withIndex && product.position !== undefined && { index: product?.position }),
                ...(withStockStatus &&
                    product?.stockStatus !== undefined && { stockStatus: product?.stockStatus }),
            };
        });
    } catch (error) {
        console.warn('_transformProductsToGAItems', error);
        return [];
    }
}

export function _transformGAEvent(eventCategory: string, eventAction: string, eventLabel: string) {
    return {
        event: 'gaEvent',
        eventCategory,
        eventAction,
        eventLabel,
    };
}

export function _getTotalValue(items: GTMProductEventModel[]) {
    return items.reduce((acc, item) => {
        return acc + item.price * (item.quantity ?? 1);
    }, 0);
}

export function _getBasePrice(items: GTMProductEventModel[]) {
    const [item] = items;
    return item.price;
}
